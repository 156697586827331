import React, { useContext } from "react"

type Args = {
  title: string
  input?: {
    label?: string
    value?: string | number
    type?: "text" | "number"
    multiline?: boolean
    rows?: number
  }
  okLabel?: string
  cancelLabel?: string
  text?: string
  required?: boolean
}

export type RequestValueDialogContextValues = (args: Args) => Promise<string>

export const RequestValueDialogContext = React.createContext<RequestValueDialogContextValues>(
  () => Promise.resolve("")
)

export const useRequestValue = (): RequestValueDialogContextValues => {
  return useContext(RequestValueDialogContext)
}
