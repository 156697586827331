/**
 *
 * NotificationStack
 *
 */

import React from "react"
import PropTypes from "prop-types"
import { createStructuredSelector } from "reselect"
import { NotificationStack as Stack } from "react-notification-upgrade"
import theReducer from "./reducer"

import makeSelectNotifications from "./selectors"
import { removeNotification } from "./actions"

export class NotificationStack extends React.Component {
  render() {
    const { notifications } = this.props
    return (
      <Stack
        notifications={notifications.map((notification) => ({
          ...notification,
          onClick: (n, deactivate) => {
            deactivate()
            setTimeout(() => this.props.dispatch(removeNotification(n)), 1000)
          }
        }))}
        onDismiss={(n) => this.props.dispatch(removeNotification(n))}
      />
    )
  }
}

NotificationStack.propTypes = {
  notifications: PropTypes.any,
  dispatch: PropTypes.func.isRequired
}

export const mapStateToProps = createStructuredSelector({
  notifications: makeSelectNotifications()
})

export function mapDispatchToProps(dispatch) {
  return {
    dispatch
  }
}

export const reducer = theReducer
