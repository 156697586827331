import { fromJS } from "immutable"
import {
  FOOD_TYPE_CATEGORIES_LOADED,
  INVALIDATE_ENTITY_CACHE,
  ENTITY_LOADED
} from "./constants"

const initialState = fromJS({
  foodTypeCategories: null
})

function entitiesReducer(state = initialState, action) {
  switch (action.type) {
    case INVALIDATE_ENTITY_CACHE:
      return state.set(action.entityName, null)
    case ENTITY_LOADED:
      return state.setIn(action.storePath, fromJS(action.data))
    case FOOD_TYPE_CATEGORIES_LOADED:
      return state.set("foodTypeCategories", fromJS(action.foodTypeCategories))
    default:
      return state
  }
}

export default entitiesReducer
