/*
 *
 * NotificationStack actions
 *
 */

import { ADD_NOTIFICATION, REMOVE_NOTIFICATION } from "./constants"
import messageFromResponse from "common/utils/messageFromResponse"

export function errorNotification(
  response,
  alternativeMessage = "Some error occurred"
) {
  const message = messageFromResponse(response, alternativeMessage)
  return {
    type: ADD_NOTIFICATION,
    notification: {
      message,
      type: "error"
    }
  }
}

export function successNotification(message, options = {}) {
  return {
    type: ADD_NOTIFICATION,
    notification: {
      message,
      action: "OK",
      barStyle: { color: "#00ff00" },
      actionStyle: { color: "#ffffff" },
      ...options
    }
  }
}

export function addNotification(notification) {
  return {
    type: ADD_NOTIFICATION,
    notification
  }
}

export function removeNotification(notification) {
  return {
    type: REMOVE_NOTIFICATION,
    notification
  }
}
