import { createSelector } from "reselect"
import { initialState } from "./reducer"

/**
 * Direct selector to the notification stack
 */
const selectNotificationStack = (state) =>
  state.get("notificationStack") || initialState

/**
 * Other specific selectors
 */

const makeSelecNotifications = () =>
  createSelector(selectNotificationStack, (stack) =>
    stack.get("notifications").toJS()
  )

export default makeSelecNotifications
export { selectNotificationStack, makeSelecNotifications }
