import { fromJS } from "immutable"
import { USER_LOADED, USER_NOT_LOADED } from "common/constants"
import { LOGOUT, USER_LOGGED_IN } from "common/auth/constants"

const initialState = null

function userReducer(state = initialState, action) {
  switch (action.type) {
    case USER_LOGGED_IN:
    case USER_LOADED:
      return fromJS(action.user)
    case USER_NOT_LOADED:
    case LOGOUT:
      return null
    default:
      return state
  }
}

export default userReducer
