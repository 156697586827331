import { ApolloProvider } from "@apollo/client"
import Rollbar from "common/utils/Rollbar"
import GlobalStyle from "common/components/GlobalStyle"

// Import all the third party stuff
import React, { useEffect, useState } from "react"
import { Provider } from "react-redux"
import { ConnectedRouter } from "connected-react-router/immutable"
import "sanitize.css/sanitize.css"
import { ThemeProvider as SCTHemeProvider } from "styled-components"
import {
  MuiThemeProvider as ThemeProvider,
  StylesProvider
} from "@material-ui/core/styles"

import ActionCableProvider from "common/components/ActionCableProvider"
import LogEventFeedContext from "common/components/LogEventFeed/LogEventFeedContext"
import { ConfirmDialogContext } from "common/components/ReactConfirmation/ReactConfirmationProvider"
import { RequestValueDialogContext } from "common/components/RequestValueDialog/RequestValueDialogProvider"
import {
  ApolloClient,
  InMemoryCache,
  defaultDataIdFromObject
} from "@apollo/client"
import { from as fromLinks, split as splitLink } from "@apollo/client/link/core"
import { BatchHttpLink } from "@apollo/client/link/batch-http"
import history from "common/utils/history"
import apolloAuthMiddleware from "common/apolloAuth"
import { createPersistedQueryLink } from "@apollo/client/link/persisted-queries"
import { sha256 } from "js-sha256"
import { createUploadLink } from "apollo-upload-client"
import config from "common/config"
import errorLink from "./apolloError"
import { DAEMON } from "common/utils/constants"
import { loadApp } from "common/actions"
import getInjectors from "common/utils/sagaInjectors"
import formActionSaga from "common/pkg/redux-form-saga"
import PageLoader from "common/components/PageLoader"

window.reactRouterHistory = history

Rollbar.init()

const WithStyles = ({ children, jss, muiTheme, theme }) => (
  <React.Fragment>
    <GlobalStyle />
    <StylesProvider jss={jss}>
      <ThemeProvider theme={muiTheme}>
        <SCTHemeProvider theme={theme}>{children}</SCTHemeProvider>
      </ThemeProvider>
    </StylesProvider>
  </React.Fragment>
)

async function sha256WithFallback(message) {
  if (!window.crypto || !window.crypto.subtle) {
    return sha256.sha256(message)
  }

  // encode as UTF-8
  const msgBuffer = new TextEncoder().encode(message)

  // hash the message
  const hashBuffer = await crypto.subtle.digest("SHA-256", msgBuffer)

  // convert ArrayBuffer to Array
  const hashArray = Array.from(new Uint8Array(hashBuffer))

  // convert bytes to hex string
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, "0")).join("")
  return hashHex
}

const ConnectedApp = ({
  jss,
  muiTheme,
  theme,
  logEventConfig,
  authTokenName,
  authEmailName,
  configureStore,
  confirmDialog,
  requestValue,
  children
}) => {
  const [{ store, client }, setInitialValues] = useState({})
  useEffect(() => {
    const httpLink = createUploadLink({
      uri: `${config.host}/graphql`
    })
    const batchHttpLink = new BatchHttpLink({
      uri: `${config.host}/graphql`,
      batchMax: 30
    })
    const initialState = {}
    const store = configureStore(initialState, history)

    const authMiddleware = apolloAuthMiddleware({
      authTokenName,
      authEmailName,
      store
    })

    const cache = new InMemoryCache({
      dataIdFromObject: (value) => {
        switch (value.__typename) {
          case "QuickSearchMatch": {
            return null
          }
          case "CateringMealPart": {
            return null
          }
          case "HtmlRecord": {
            return null
          }
          case "ImageAttachment": {
            return null
          }
          default: {
            return defaultDataIdFromObject(value)
          }
        }
      }
    })

    const persistedQueriesLink = createPersistedQueryLink({
      sha256: sha256WithFallback
    })

    const client = new ApolloClient({
      assumeImmutableResults: true,
      link: fromLinks([
        authMiddleware,
        errorLink,
        persistedQueriesLink,
        splitLink(
          ({ operationName }) => {
            return ["notesQuery"].includes(operationName)
          },
          batchHttpLink,
          httpLink
        )
      ]),
      cache
    })

    window.__APOLLO_CLIENT__ = client

    const start = () => {
      setInitialValues({ client, store })

      const { injectSaga } = getInjectors(store)
      injectSaga("formActionSaga", {
        saga: formActionSaga,
        mode: DAEMON
      })
      store.dispatch(loadApp())
    }
    start()
    return () => {}
  }, [authEmailName, authTokenName, configureStore])

  if (client === undefined)
    return (
      <WithStyles jss={jss} muiTheme={muiTheme} theme={theme}>
        <PageLoader />
      </WithStyles>
    )

  return (
    <WithStyles jss={jss} muiTheme={muiTheme} theme={theme}>
      <Provider store={store}>
        <ApolloProvider client={client}>
          <LogEventFeedContext.Provider value={logEventConfig}>
            <ActionCableProvider>
              <ConfirmDialogContext.Provider value={confirmDialog}>
                <RequestValueDialogContext.Provider value={requestValue}>
                  <ConnectedRouter history={history}>
                    {children}
                  </ConnectedRouter>
                </RequestValueDialogContext.Provider>
              </ConfirmDialogContext.Provider>
            </ActionCableProvider>
          </LogEventFeedContext.Provider>
        </ApolloProvider>
      </Provider>
    </WithStyles>
  )
}

export default ConnectedApp
