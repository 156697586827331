import { applyMiddleware, compose, createStore } from "redux"
import { routerMiddleware } from "connected-react-router/immutable"
import { fromJS } from "immutable"
import createSagaMiddleware from "redux-saga"
import Rollbar, {
  reducerMiddleware as rollbarReducerMiddleware
} from "common/utils/Rollbar"

const sagaMiddleware = createSagaMiddleware()

export default function configureStore(history, initialState, createReducer) {
  // Create the store with two middlewares
  // 1. sagaMiddleware: Makes redux-sagas work
  // 2. routerMiddleware: Syncs the location/URL path to the state
  const middlewares = [
    sagaMiddleware,
    rollbarReducerMiddleware(),
    routerMiddleware(history)
  ]

  const enhancers = [applyMiddleware(...middlewares)]

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* eslint-disable no-underscore-dangle */
  const composeEnhancers =
    typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose
  /* eslint-enable */

  const store = createStore(
    createReducer(),
    fromJS(initialState),
    composeEnhancers(...enhancers)
  )

  // Extensions
  store.runSaga = sagaMiddleware.run
  store.injectedReducers = {} // Reducer registry
  store.injectedSagas = {} // Saga registry

  Rollbar.setStore(store)
  return store
}
