import { ApolloLink } from "@apollo/client"

const apolloAuthMiddleware = ({ store, authTokenName, authEmailName }) =>
  new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    const auth = store.getState().get("auth")
    const context = operation.getContext()
    const extraHeaders = {}
    if (context.cacheSeconds) {
      extraHeaders["X-Query-Cache"] = context.cacheSeconds
    }
    operation.setContext({
      headers: {
        ...(context.headers || {}),
        ...extraHeaders,
        [authTokenName]: auth.get("token") || "",
        [authEmailName]: auth.get("email") || ""
      }
    })

    return forward(operation)
  })

export default apolloAuthMiddleware
