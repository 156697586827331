import React from "react"
import hoistNonReactStatics from "hoist-non-react-statics"
import { ReactReduxContext } from "react-redux"

import getInjectors from "./reducerInjectors"

/**
 * Dynamically injects a reducer
 *
 * @param {string} key A key of the reducer
 * @param {function} reducer A reducer that will be injected
 *
 */
const createInjectReducer = (createReducer) => ({ key, reducer }) => (
  WrappedComponent
) => {
  class ReducerInjector extends React.Component {
    static WrappedComponent = WrappedComponent
    static contextType = ReactReduxContext
    static displayName = `withReducer(${
      WrappedComponent.displayName || WrappedComponent.name || "Component"
    })`

    constructor(props, context) {
      super(props, context)
      this.state = {
        injected: false
      }
    }

    componentDidMount() {
      const { store } = this.context
      getInjectors(store, createReducer).injectReducer(key, reducer)
      this.setState({
        injected: true
      })
    }

    render() {
      return this.state.injected ? <WrappedComponent {...this.props} /> : null
    }
  }

  return hoistNonReactStatics(ReducerInjector, WrappedComponent)
}

export default createInjectReducer
