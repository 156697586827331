import * as actioncable from "@rails/actioncable"
import config from "common/config"

const ActionCable = {
  subscriptions: {},
  pendingSubscriptions: [],

  initialize(user) {
    const authenticationToken =
      user && (user.authenticationToken || user.authToken || user.token)
    if (!user || !authenticationToken) return
    if (this.cable) {
      if (this.authenticationToken !== authenticationToken) {
        this.disconnect()
        this.authenticationToken = authenticationToken
        this.email = user.email
        this.connect()
      }
    } else {
      this.authenticationToken = authenticationToken
      this.email = user.email
      this.connect()
    }
  },

  subscribe(name, channel, callback = () => {}, params = {}) {
    if (this.subscriptions[name]) return
    if (this.cable) {
      this.performSubscription(name, channel, callback, params)
    } else {
      this.log(`CABLE - delaying subscription: ${name}`)
      this.pendingSubscriptions.push([name, channel, callback, params])
    }
  },

  unsubscribe(name) {
    this.pendingSubscriptions = this.pendingSubscriptions.filter(
      ([_name]) => _name !== name
    )
    if (this.subscriptions[name]) {
      this.log(`CABLE - unsubscribed: ${name}`)
      this.cable.subscriptions.remove(this.subscriptions[name])
      delete this.subscriptions[name]
    }
  },

  connect() {
    this.log("CABLE - connect")
    const host = config.host.replace(/^http/, "ws")
    const cableUrl = `${host}/cable?auth_token=${
      this.authenticationToken
    }&email=${window.encodeURIComponent(this.email)}`
    this.cable = actioncable.createConsumer(cableUrl)
    this.runPendingSubscriptions()
  },

  disconnect() {
    this.pendingSubscriptions = []
    if (this.cable) {
      this.log("CABLE - disconnect")
      this.subscriptions = {}
      this.cable.disconnect()
      this.cable = null
    }
  },

  runPendingSubscriptions() {
    this.pendingSubscriptions.forEach((args) => {
      this.performSubscription.apply(this, args) // eslint-disable-line
    })
    this.pendingSubscriptions = []
  },

  performSubscription(name, channel, callback, params) {
    this.log(`CABLE - subscribed: ${name}`)
    this.subscriptions[name] = this.cable.subscriptions.create(
      { channel, ...params },
      {
        received: callback
      }
    )
  },

  log(message) {
    console.log(message) // eslint-disable-line
  }
}

export default ActionCable
