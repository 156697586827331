import React from "react"
import BlockUi from "react-block-ui"
import PropTypes from "prop-types"
import { createStructuredSelector } from "reselect"
import theReducer from "./reducer"
import { makeSelectBlockUiBlocks } from "./selectors"
import styles from "./styles.module.scss"
import CircularProgress from "@material-ui/core/CircularProgress"

export class BlockUIConnected extends React.PureComponent {
  static renderBlockUiImage() {
    return <CircularProgress />
  }
  render() {
    const { blocks } = this.props
    const blocking = blocks > 0
    return (
      <BlockUi
        tag="div"
        className={blocking ? styles.blockUIContainer : ""}
        blocking={blocking}
        loader={BlockUIConnected.renderBlockUiImage()}
      />
    )
  }
}

export const mapStateToProps = createStructuredSelector({
  blocks: makeSelectBlockUiBlocks()
})

BlockUIConnected.propTypes = {
  blocks: PropTypes.any
}

export const reducer = theReducer
