import { graphql } from "@apollo/client/react/hoc"
import gql from "graphql-tag"
import { userLoaded, userNotLoaded } from "common/actions"

const QUERY = gql`
  query currentAdminQuery {
    currentAdmin {
      id
      name
      email
      authToken
    }
  }
`

const withUser = graphql(QUERY, {
  options: ({ dispatch }) => ({
    fetchPolicy: "cache",
    onCompleted: (r) => {
      const user = r.currentAdmin
      if (user) {
        dispatch(userLoaded({ user }))
      } else {
        dispatch(userNotLoaded())
      }
    }
  }),
  props: ({ data: { loading, currentAdmin } }) => ({
    userLoading: loading,
    user: currentAdmin
  })
})

export default withUser
