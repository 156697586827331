/*
 *
 * NotificationStack reducer
 *
 */

import { fromJS } from "immutable"
import uuid from "uuid-random"
import { ADD_NOTIFICATION, REMOVE_NOTIFICATION } from "./constants"

export const initialState = fromJS({
  notifications: []
})

function makeNotification({ message, type, ...rest }) {
  const action = "Dismiss"
  const result = {
    message,
    type,
    action,
    dismissAfter: type === "error" ? false : 6000,
    key: uuid(),
    ...rest
  }
  return fromJS(result)
}

function stackReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_NOTIFICATION:
      return state.updateIn(["notifications"], (notifications) =>
        notifications.push(makeNotification(action.notification))
      )
    case REMOVE_NOTIFICATION:
      return state.updateIn(["notifications"], (notifications) =>
        notifications.filter((n) => n.get("key") !== action.notification.key)
      )
    default:
      return state
  }
}

export default stackReducer
