const drawerWidth = 240

const navbarStyles = (theme) => ({
  navIconHide: {
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  drawerPaper: {
    width: 250,
    [theme.breakpoints.up("md")]: {
      width: drawerWidth + 1,
      position: "relative",
      height: "100%"
    }
  },
  appBar: {
    position: "absolute",
    marginLeft: drawerWidth,
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth + 1}px)`
    }
  },
  appBarRoot: {
    boxShadow: "none",
    position: "fixed"
  },
  appFrame: {
    position: "relative",
    display: "flex",
    width: "100%",
    height: "100%"
  },
  content: {
    backgroundColor: theme.palette.background.default,
    width: "100%",
    height: "calc(100% - 56px)",
    marginTop: 56,
    [theme.breakpoints.up("md")]: {
      marginLeft: drawerWidth + 1
    },
    [theme.breakpoints.up("sm")]: {
      height: "calc(100% - 64px)",
      marginTop: 64
    },
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 50
    }
  },
  drawerHeader: theme.mixins.toolbar,
  drawerDocked: {
    position: "fixed",
    bottom: 0,
    top: 0,
    left: 0,
    zIndex: 1000
  },
  buttonUserName: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "inline"
    }
  },
  menuUserName: {
    display: "block",
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  }
})

export default navbarStyles
