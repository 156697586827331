import gql from "graphql-tag"

export default gql`
  query adminQuickSearch($search: String!) {
    quickSearch: adminQuickSearch(search: $search) {
      id
      type
      name
      description
      path
      active
    }
  }
`
