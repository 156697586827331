import { createSelector } from "reselect"
import { initialState } from "./reducer"

/**
 * Direct selector to the sidebar
 */
const selectSidebar = (state) => state.get("sidebar") || initialState
const selectLocation = (state) => state.getIn(["router", "location"])

/**
 * Other specific selectors
 */

/**
 * Default selector used by Sidebar
 */

const makeSelectSidebar = () =>
  createSelector(selectSidebar, selectLocation, (sidebar, location) => ({
    state: sidebar && sidebar.toJS(),
    location: location && location.toJS()
  }))

export default makeSelectSidebar
export { selectSidebar }
