/**
 * Create the store with dynamic reducers
 */

import createReducer from "./reducers"
import commonConfigureStore from "common/configureStore"

export default function configureStore(initialState = {}, history) {
  return commonConfigureStore(history, initialState, createReducer)
}
