import React from "react"
import PropTypes from "prop-types"
import { confirmable } from "react-confirm"
import {
  MuiThemeProvider as ThemeProvider,
  StylesProvider
} from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContentText from "@material-ui/core/DialogContentText"
import styled from "styled-components"

const StyledDialogContent = styled(DialogContent)`
  min-width: 350px;
`

class RequestValueDialog extends React.Component {
  static propTypes = {
    theme: PropTypes.object,
    okLabel: PropTypes.string,
    cancelLabel: PropTypes.string,
    required: PropTypes.bool,
    title: PropTypes.string,
    text: PropTypes.string,
    show: PropTypes.bool,
    proceed: PropTypes.func,
    dismiss: PropTypes.func,
    reject: PropTypes.func,
    resolve: PropTypes.func,
    dispose: PropTypes.func,
    cancel: PropTypes.func,
    input: PropTypes.object,
    force: PropTypes.bool,
    jss: PropTypes.any
  }

  static defaultProps = {
    input: {
      value: "",
      type: "text"
    },
    force: false
  }

  state = {
    value: ""
  }

  UNSAFE_componentWillMount() {
    this.setState({
      value: this.props.input.value || ""
    })
  }

  componentDidMount() {
    this.unlisten = window.reactRouterHistory.listen(() => {
      this.props.cancel()
    })
  }

  componentWillUnmount() {
    this.unlisten()
  }

  submit = () => {
    this.props.proceed(this.state.value)
  }

  onChange = (e) => {
    this.setState({
      value: e.target.value
    })
  }

  render() {
    const {
      theme,
      jss,
      okLabel = "OK",
      cancelLabel = "Cancel",
      required = true,
      title,
      text,
      show,
      proceed,
      cancel,
      dismiss,
      reject,
      resolve,
      dispose,
      force,
      ...other
    } = this.props
    return (
      <StylesProvider jss={jss}>
        <ThemeProvider theme={theme}>
          <Dialog
            disableBackdropClick={force}
            disableEscapeKeyDown={force}
            fullScreen={false}
            onClose={force ? undefined : cancel}
            maxWidth="md"
            open={show}
            {...other}
          >
            <DialogTitle>{title}</DialogTitle>
            <StyledDialogContent>
              <DialogContentText>{text}</DialogContentText>
              <TextField
                {...this.props.input}
                onChange={this.onChange}
                fullWidth
                autoFocus
                value={this.state.value}
              />
            </StyledDialogContent>
            <DialogActions>
              {!force && (
                <Button onClick={cancel} color="default">
                  {cancelLabel}
                </Button>
              )}
              <Button
                onClick={this.submit}
                color="primary"
                variant="contained"
                autoFocus
                disabled={required && this.state.value.length === 0}
              >
                {okLabel}
              </Button>
            </DialogActions>
          </Dialog>
        </ThemeProvider>
      </StylesProvider>
    )
  }
}

export default confirmable(RequestValueDialog)
