import { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import ActionCable from "common/ActionCable"

class ActionCableProvider extends Component {
  constructor(props, context) {
    super(props, context)
    this.subscribe = this.subscribe.bind(this)
    this.unsubscribe = this.unsubscribe.bind(this)
  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  UNSAFE_componentWillMount() {
    if (this.props.user) {
      this.subscribe(this.props.user)
    }
  }
  //
  // getChildContext() {
  //   return { cable: ActionCable }
  // }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.user && nextProps.user) {
      this.subscribe(nextProps.user)
    } else if (this.props.user && !nextProps.user) {
      this.unsubscribe()
    }
  }

  subscribe(user) {
    ActionCable.initialize(user ? user.toJS() : null)
  }

  unsubscribe() {
    ActionCable.disconnect()
  }

  render() {
    return this.props.children
  }
}

ActionCableProvider.propTypes = {
  children: PropTypes.any,
  user: PropTypes.object
}

// ActionCableProvider.childContextTypes = {
//   cable: PropTypes.object
// }

const mapStateToProps = (state) => ({
  user: state.get("auth") || state.get("user")
})

const mapDispatchToProps = (dispatch) => ({
  dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(ActionCableProvider)
