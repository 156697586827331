/**
 *
 * Asynchronously loads the component for HomePage
 *
 */

import loadable from "common/utils/loadable"
import LoadingIndicator from "common/components/PageLoader"

export default loadable(() => import("./index"), {
  fallback: (<LoadingIndicator />) as any
})
