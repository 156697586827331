/*
 *
 * Navbar actions
 *
 */

import { CLOSE_SIDEBAR, OPEN_SIDEBAR } from "./constants"

export function closeSidebar() {
  return {
    type: CLOSE_SIDEBAR
  }
}

export function openSidebar() {
  return {
    type: OPEN_SIDEBAR
  }
}
