/*
 *
 * App actions
 *
 */

import { LOAD_APP, USER_LOADED, USER_NOT_LOADED } from "common/constants"

export function loadApp() {
  return {
    type: LOAD_APP
  }
}

export function userLoaded({ user }) {
  return {
    type: USER_LOADED,
    user
  }
}

export function userNotLoaded() {
  return {
    type: USER_NOT_LOADED
  }
}
