import find from "lodash/find"
/**
 *
 * Navbar
 *
 */

import React from "react"
import { createStructuredSelector } from "reselect"
import { compose } from "redux"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import styled from "styled-components"

import { push } from "connected-react-router/immutable"
import injectReducer from "admins/utils/injectReducer"
import makeSelectSidebar from "./selectors"
import reducer from "./reducer"
import { makeSelectUser } from "common/selectors"
import Divider from "@material-ui/core/Divider"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Avatar from "@material-ui/core/Avatar"
import bftLogo from "common/images/bft-logo-black-medium.png"
import { closeSidebar } from "admins/components/Navbar/actions"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"

const Header = styled.div`
  min-height: 64px;
  ${({ theme }) => theme.media.md`min-height: 56px`};
`
const HeaderImg = styled.img`
  height: 56px;
  width: 240px;
`

const StyledListItem = styled(ListItem)`
  ${({ current }) =>
    current === "true" && "background-color: rgba(0, 0, 0, 0.12) !important;"};
`

class Sidebar extends React.Component {
  isActive = (page, path) => !!find(page.matches, (match) => match.test(path))

  makeGoToLink = (page) => () => {
    const { dispatch } = this.props
    dispatch(push(page.link))
    dispatch(closeSidebar())
  }

  render() {
    const { user, sidebar } = this.props
    const { state, location } = sidebar
    const header = (
      <Header>
        <HeaderImg src={bftLogo} alt="" />
      </Header>
    )
    return (
      <div>
        {header}
        <Divider />
        <List>
          {state.pages
            .filter(({ noSession }) => (user ? !noSession : noSession))
            .map((p, i) => (
              <StyledListItem
                button
                onClick={this.makeGoToLink(p)}
                current={`${this.isActive(p, location.pathname)}`}
                key={i}
              >
                <ListItemAvatar>
                  <Avatar>{p.icon}</Avatar>
                </ListItemAvatar>
                <ListItemText primary={p.name} />
              </StyledListItem>
            ))}
        </List>
      </div>
    )
  }
}

Sidebar.propTypes = {
  user: PropTypes.object,
  sidebar: PropTypes.object,
  dispatch: PropTypes.func
}

const mapStateToProps = createStructuredSelector({
  sidebar: makeSelectSidebar(),
  user: makeSelectUser()
})

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  }
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)
const withReducer = injectReducer({
  key: "sidebar",
  reducer
})

export default compose(withReducer, withConnect)(Sidebar)
