/**
 *
 * PageLoader
 *
 */

import React from "react"
import styled from "styled-components"
import CircularProgress from "@material-ui/core/CircularProgress"

const LoadingContainer = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
  width: 50px;
  height: 50px;
  margin: -100px 0 0 -25px;
  @media (min-width: ${({ theme }) => theme.dimensions.tabletWidth}px) {
    width: 75px;
    height: 75px;
    margin: -125px 0 0 -38px;
  }
  @media (min-width: ${({ theme }) => theme.dimensions.desktopWidth}px) {
    width: 100px;
    height: 100px;
    margin: -150px 0 0 -50px;
  }
  img {
    width: 50px;
    height: 50px;
    @media (min-width: ${({ theme }) => theme.dimensions.tabletWidth}px) {
      width: 75px;
      height: 75px;
    }
    @media (min-width: ${({ theme }) => theme.dimensions.desktopWidth}px) {
      width: 100px;
      height: 100px;
    }
  }
`

const PageLoader = () => (
  <LoadingContainer>
    <CircularProgress />
  </LoadingContainer>
)

export default PageLoader
