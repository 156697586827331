import React, { lazy, Suspense } from "react"

function loadable(importFunc, { fallback = null } = { fallback: null }) {
  const LazyComponent = lazy(importFunc)

  return function LoadableWrapper(props) {
    return (
      <Suspense fallback={fallback}>
        <LazyComponent {...props} />
      </Suspense>
    )
  }
}

export default loadable
