/*
 *
 * Sidebar reducer
 *
 */

import React from "react"
import { fromJS } from "immutable"
import HomeIcon from "@material-ui/icons/Home"
import ListIcon from "@material-ui/icons/List"
import AccountBoxIcon from "@material-ui/icons/AccountBox"
import SettingsIcon from "@material-ui/icons/SettingsOutlined"
import RestaurantMenu from "@material-ui/icons/RestaurantMenu"
import RestaurantIcon from "@material-ui/icons/Restaurant"
import EmailIcon from "@material-ui/icons/Email"
import PlaceIcon from "@material-ui/icons/Place"
import WorkIcon from "@material-ui/icons/Work"
import ChatIcon from "@material-ui/icons/Chat"
import StoreMallDirectoryIcon from "@material-ui/icons/StoreMallDirectory"
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle"
import {} from "./constants"

export const initialState = fromJS({
  pages: [
    {
      name: "Log In",
      link: "/login",
      noSession: true,
      matches: [/^\/login$/],
      icon: <AccountBoxIcon />
    },
    {
      name: "Live Locations",
      link: "/",
      matches: [/^\/$/],
      icon: <HomeIcon />
    },
    {
      name: "Feed",
      link: "/feed",
      matches: [/^\/feed.*$/],
      icon: <ListIcon />
    },
    {
      name: "Emails",
      link: "/support-conversations",
      matches: [/^\/support-conversations.*$/],
      icon: <EmailIcon />
    },
    {
      name: "Chats",
      link: "/chat-conversations",
      matches: [/^\/chat-conversations.*$/],
      icon: <ChatIcon />
    },
    {
      name: "Catering",
      link: "/catering-requests",
      matches: [/^\/catering-requests.*$/],
      icon: <RestaurantIcon />
    },
    {
      name: "Events",
      link: "/events-requests",
      matches: [/^\/events-requests.*$/],
      icon: <RestaurantIcon />
    },
    {
      name: "Custom Events",
      link: "/custom-event-requests",
      matches: [/^\/custom-event-requests.*$/],
      icon: <RestaurantIcon />
    },
    {
      name: "Organizations",
      link: "/organizations",
      matches: [/^\/organizations.*$/],
      icon: <StoreMallDirectoryIcon />
    },
    {
      name: "Corporate",
      link: "/corporate",
      matches: [/^\/corporate.*$/],
      icon: <WorkIcon />
    },
    {
      name: "Customers",
      link: "/customers",
      matches: [/^\/customers.*$/],
      icon: <SupervisedUserCircleIcon />
    },
    {
      name: "Locations",
      link: "/locations",
      matches: [/^\/locations.*$/],
      icon: <PlaceIcon />
    },
    {
      name: "Restaurants",
      link: "/restaurants",
      matches: [/^\/restaurants.*$/, /^\/trucks-review.*$/],
      icon: <RestaurantMenu />
    },
    {
      name: "Builders",
      link: "/builders",
      matches: [/^\/builders/],
      icon: <SettingsIcon />
    }
  ]
})

function navbarReducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state
  }
}

export default navbarReducer
