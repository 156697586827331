import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import SearchIcon from "@material-ui/icons/Search"
import CircularProgress from "@material-ui/core/CircularProgress"

const Wrapper = styled.div`
  border-radius: 2px;
  position: relative;
  display: flex;
  flex: 1;
  background: rgba(255, 255, 255, 0.15);
  &:hover,
  &:focus-within {
    background: rgba(255, 255, 255, 0.25);
    outline: 1px solid transparent;
  }
  @media (max-width: 600px) {
    width: 72px;
    &:focus-within {
      width: inherit;
    }
  }
`

const IconWrapper = styled.div`
  display: inline-block;
  height: 36px;
  position: relative;
  width: 72px;
`
const InputWrapper = styled.div`
  position: relative;
  flex: 1;
`

const Input = styled.input`
  background: none;
  border: none;
  box-sizing: border-box;
  color: #fff;
  display: block;
  height: 36px;
  padding-right: 10px;
  position: absolute;
  width: 100%;
  margin: 0;
  outline: none;
  cursor: text;
  @media (max-width: 600px) {
    width: 0px;
    &:focus {
      width: 100%;
    }
  }
`

const Icon = styled(SearchIcon)`
  height: 24px;
  left: 24px;
  position: absolute;
  top: 6px;
  width: 24px;
  transition: transform 0.15s linear;
`

const LoadingIcon = styled(CircularProgress).attrs({
  size: 20
})`
  color: white !important;
  margin-right: 10px;
  height: 24px;
  left: 24px;
  position: absolute;
  top: 6px;
  width: 24px;
  transition: transform 0.15s linear;
`

class GoogleStyledInput extends React.PureComponent {
  static propTypes = {
    inputProps: PropTypes.object,
    loading: PropTypes.bool
  }

  static defaultProps = {
    inputProps: {},
    loading: false
  }

  focusInput = () => {
    this.inputEl.focus()
  }

  render() {
    const {
      loading,
      inputProps: { ref, ...inputProps }
    } = this.props

    return (
      <Wrapper>
        <IconWrapper onClick={this.focusInput}>
          {loading ? <LoadingIcon /> : <Icon />}
        </IconWrapper>
        <InputWrapper>
          <Input
            {...inputProps}
            ref={(el) => {
              this.inputEl = el
              if (ref) ref(el)
            }}
          />
        </InputWrapper>
      </Wrapper>
    )
  }
}

export default GoogleStyledInput
