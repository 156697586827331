import isString from "lodash/isString"
// https://rollbar.com/docs/notifier/rollbar.js
import rollbar from "rollbar"

const decycle = (obj) => {
  if (isString(obj)) return obj

  let cache = []

  const stringified = JSON.stringify(obj, (key, value) => {
    if (typeof value === "object" && value !== null) {
      if (cache.indexOf(value) !== -1) {
        return
      }
      cache.push(value)
    }
    return value
  })
  cache = null

  return stringified
}

const Rollbar = {
  init() {
    const environment = import.meta.env.PROD ? "production" : "development"
    const rollbarConfig = {
      enabled: environment !== "development" && environment !== "test",
      accessToken: "50b8eb57d3ff470fab89042c772baf4a",
      captureUncaught: true,
      captureUnhandledRejections: false,
      autoInstrument: true,
      payload: {
        environment,
        client: {
          javascript: {
            source_map_enabled: true,
            guess_uncaught_frames: true,
            code_version: import.meta.env.VITE_VERCEL_GIT_COMMIT_SHA
          }
        }
      },
      transform: this.transformPayload
    }
    this.instance = new rollbar(rollbarConfig) // eslint-disable-line
    const authEmail = window.localStorage.getItem("authEmail")
    if (authEmail) {
      this.setUser({
        id: authEmail,
        email: authEmail
      })
    }
    window.Rollbar = this.instance
  },

  setUser(user) {
    if (user) {
      this.instance.configure({
        payload: {
          person: {
            id: user.id,
            email: user.email
          }
        }
      })
    } else {
      this.instance.configure({
        payload: {
          person: null
        }
      })
    }
  },

  notify(message, e, data) {
    this.instance.error(message, e, data)
  },

  captureEvent(data, level = "info") {
    this.instance.captureEvent(data, level)
  },

  setStore(store) {
    this.reduxStore = store
  },

  getStore() {
    return this.reduxStore
  },

  transformPayload(payload) {
    const store = Rollbar.getStore()
    if (store) {
      // eslint-disable-next-line no-param-reassign
      payload.reduxState = decycle(store.getState())
    }
  }
}

export default Rollbar

export function reducerMiddleware() {
  // eslint-disable-next-line
  return (store) => (next) => (action) => {
    try {
      if (action) {
        Rollbar.captureEvent({
          type: "reduxAction",
          action: decycle(action)
        })
      }
      return next(action)
    } catch (err) {
      const logFunc = console.error || console.log // eslint-disable-line
      logFunc(err)
      if (action) {
        Rollbar.notify(`Reducer error: ${err.message}`, err, {
          action
        })
      } else {
        throw err
      }
    }
  }
}
