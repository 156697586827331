let webHost

if (import.meta.env.PROD) {
  webHost = "https://www.bestfoodtrucks.com"
} else {
  webHost = "http://customers.bft.com"
}

const config = {
  host: import.meta.env.VITE_API_HOST || "http://api.bft.com",
  cdnHost: import.meta.env.VITE_CDN_HOST || "/",
  webHost
}

if (config.cdnHost[config.cdnHost.length - 1] !== "/") {
  config.cdnHost = `${config.cdnHost}/`
}

export default config
