import { createSelector } from "reselect"
import { initialState } from "./reducer"

/**
 * Direct selector to the navbar
 */
const selectNavbar = (state) => state.get("navbar") || initialState

/**
 * Other specific selectors
 */

/**
 * Default selector used by Navbar
 */

const makeSelectNavbar = () =>
  createSelector(selectNavbar, (navbar) => ({
    state: navbar.toJS()
  }))

export default makeSelectNavbar
export { selectNavbar }
