import find from "lodash/find"

const logEventConfig = {
  linkHandlers: {
    Lot: ({ id }) => ({ url: `/builders/lots/${id}` }),
    Restaurant: ({ id }) => ({ url: `/restaurants/${id}` }),
    "Catering::Request": ({ id }) => ({
      url: `/catering-requests/${id}`
    }),
    CateringOtherSubmission: ({ id }) => ({
      name: "Other event",
      url: `/custom-event-requests/OTH-${id}`
    }),
    CateringRecurringSubmission: ({ id }) => ({
      name: "Recurring event",
      url: `/custom-event-requests/REC-${id}`
    }),
    Location: ({ id }) => ({
      url: `/locations/${id}/edit`
    }),
    Order: ({ id }) => ({ url: `/orders/${id}` }),
    Customer: ({ id }) => ({ url: `/customers/${id}` }),
    OrderPartialRefund: ({ id }) => ({
      url: `/refunds/${id}`
    }),
    Event: (event, entities) => {
      const lot = find(entities, { className: "Lot" })
      const lotId = lot ? lot.id : 0
      const url = `/builders/lots/${lotId}/events/${event.id}`
      return { url }
    }
  }
}

export default logEventConfig
