import React, { useContext } from "react"

export type ConfirmDialogContextValues = (
  title: string,
  text: string | React.ReactElement,
  options?: {
    okLabel?: string
    cancelLabel?: string
  }
) => Promise<void>

export const ConfirmDialogContext = React.createContext<ConfirmDialogContextValues>(
  () => Promise.resolve(undefined)
)

export const useConfirmDialog = (): ConfirmDialogContextValues => {
  return useContext(ConfirmDialogContext)
}
