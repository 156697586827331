import { createSelector } from "reselect"

const selectRoute = (state) => state.get("router")

const selectUser = (state) => state.get("user")

const makeSelectLocation = () =>
  createSelector(selectRoute, (routeState) => routeState.get("location").toJS())

const makeSelectUser = () =>
  createSelector(selectUser, (user) => (user ? user.toJS() : null))

export { makeSelectLocation, makeSelectUser }
