import React from "react"
import PropTypes from "prop-types"
import { confirmable } from "react-confirm"
import {
  MuiThemeProvider as ThemeProvider,
  StylesProvider
} from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContentText from "@material-ui/core/DialogContentText"

class ConfirmationDialog extends React.Component {
  componentDidMount() {
    this.unlisten = window.reactRouterHistory.listen(() => {
      this.props.cancel()
    })
  }

  componentWillUnmount() {
    this.unlisten()
  }

  render() {
    const {
      theme,
      jss,
      okLabel = "OK",
      cancelLabel = "Cancel",
      title,
      text,
      show,
      proceed,
      cancel,
      dismiss,
      reject,
      resolve,
      dispose,
      ...other
    } = this.props
    return (
      <StylesProvider jss={jss}>
        <ThemeProvider theme={theme}>
          <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            fullScreen={false}
            maxWidth="xs"
            open={show}
            {...other}
          >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
              <DialogContentText>{text}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={cancel} color="default">
                {cancelLabel}
              </Button>
              <Button
                onClick={proceed}
                color="primary"
                variant="contained"
                autoFocus
              >
                {okLabel}
              </Button>
            </DialogActions>
          </Dialog>
        </ThemeProvider>
      </StylesProvider>
    )
  }
}

ConfirmationDialog.propTypes = {
  theme: PropTypes.object,
  okLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  show: PropTypes.bool,
  proceed: PropTypes.func,
  dismiss: PropTypes.func,
  reject: PropTypes.func,
  resolve: PropTypes.func,
  dispose: PropTypes.func,
  cancel: PropTypes.func,
  jss: PropTypes.any
}

export default confirmable(ConfirmationDialog)
