/*
 *
 * BlockUIConnected reducer
 *
 */

import { fromJS } from "immutable"
import { START_BLOCK, STOP_BLOCK } from "./constants"

export const initialState = fromJS({
  blocks: 0
})

function blockUiReducer(state = initialState, action) {
  switch (action.type) {
    case START_BLOCK:
      return state.set("blocks", state.get("blocks") + 1)
    case STOP_BLOCK: {
      let newCount = state.get("blocks") - 1
      if (newCount < 0) {
        newCount = 0
      }
      return state.set("blocks", newCount)
    }
    default:
      return state
  }
}

export default blockUiReducer
