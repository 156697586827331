import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const ErrorContainer = styled.div`
  height: 100%;
  max-height: 100vh;
  width: 100%;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
`

const ErrorText = styled.div`
  flex: 1 1 auto;
  color: red;
`

export default class PageError extends React.Component {
  static propTypes = {
    message: PropTypes.string
  }

  static defaultProps = {
    message: "Error Displaying Widget"
  }

  render() {
    return (
      <ErrorContainer>
        <ErrorText>{this.props.message}</ErrorText>
      </ErrorContainer>
    )
  }
}
